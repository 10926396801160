import React from "react";
import '../../styles/semantic.min.css';
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import GuideLines from "../../components/foundation/guideLines";
import PageHeader from "../../components/utils/PageHeader";
import {Container, Grid} from "semantic-ui-react";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import styles from "../../components/foundation/foundation.module.css";
import layout from "../../templates/layout.module.css";

export default () => <LayoutContainer>
    <SEO title={'Anlagerichtlinien'}/>

    <MobileTabletContainer className={styles.mobile}>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Anlagerichtlinien'}
                        subHeader={'Anlagerichtlinie zur Verwaltung des Stiftungsvermögens der Deutschen Stiftung Kinderdermatologie'}/>
            <GuideLines/>
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer className={styles.desktop}>
        <div className={layout.pageWrapper}>
            <MainNavigation/>
            <Container fluid>
                <PageHeader title={'Anlagerichtlinien'}
                            subHeader={'Anlagerichtlinie zur Verwaltung des Stiftungsvermögens der Deutschen Stiftung Kinderdermatologie'}/>
                <Grid centered columns={2}>
                    <Grid.Column width={11}>
                        <GuideLines/>
                    </Grid.Column>
                    <Grid.Column width={4}/>
                </Grid>
            </Container>
            <Footer/>
        </div>
    </DesktopContainer>

</LayoutContainer>