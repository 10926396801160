import React from "react";
import {Container, Header, List, Menu, Rail, Segment} from "semantic-ui-react";
import {Link} from "@reach/router";
import styles from "./foundation.module.css";
import cx from 'classnames';
import DesktopContainer from "../../container/DesktopContainer";


const GuideLines = () => (
    <Segment className={styles.segment}>
        <DesktopContainer>
            <Rail position='right' className={styles.rail}>
                <Segment raised className={styles.menuSegment}>
                    <Menu secondary vertical className={styles.menu}>
                        <Link to="/stiftung/">
                            <Menu.Item className={styles.menuItem}>Stiftung</Menu.Item>
                        </Link>
                        <Link to="/stiftung/grundsaetze/">
                            <Menu.Item className={styles.menuItem}>Grundsätze</Menu.Item>
                        </Link>
                        <Link to="/stiftung/principles/">
                            <Menu.Item className={styles.menuItem}>Prinzipien</Menu.Item>
                        </Link>
                        <Link to="/stiftung/transparenz/">
                            <Menu.Item className={styles.menuItem}>Transparenz</Menu.Item>
                        </Link>
                        <Link to="/stiftung/anlagerichtlinien/">
                            <Menu.Item className={cx(styles.menuItem, styles.active)}>Anlagerichtlinien</Menu.Item>
                        </Link>
                    </Menu>
                </Segment>
            </Rail>
        </DesktopContainer>
        <Container className={styles.container}>
        <p>Die Grundlage für die Richtlinie zur Verwaltung des Stiftungsvermögens der Deutschen Stiftung Kinderdermatologie stellt die Stiftungssatzung vom 17. November 2010 dar. Der Stiftungsvorstand hat auf dieser Grundlage eine Anlagerichtlinie beschlossen, die ein nachhaltiges, soziales und umweltverträgliches Wirtschaften und einen grundsätzlichen Sicherheitsgedanken beinhaltet.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                I. Grundstockvermögen
            </Header>
            <p>Das der Stiftung zur dauernden und nachhaltigen Erfüllung ihres Stiftungszwecks zugewendete Vermögen (Grundstockvermögen) ist sicher und wirtschaftlich zu verwalten und in seinem Wert ungeschmälert zu erhalten.</p>
            <p>Zustiftungen (Zuwendungen zum Grundstockvermögen) sind zulässig, diese wachsen dem Grundstockvermögen an. Zuwendungen ohne Zweckbestimmung aufgrund einer Verfügung von Todes wegen können dem Grundstockvermögen zugeführt werden. </p>
            <p>Umschichtungen des Grundstockvermögens sind zulässig. Entstehen bei der Veräußerung von Gegenständen des Grundstockvermögens Gewinne, so sind diese in einer Umschichtungsrücklage auszuweisen, die entweder zum Ausgleich von Verlusten verwendet oder sowohl zum Grundstockvermögen als auch zur satzungsgemäßen Mittelverwendung aufgelöst werden kann.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>

            </Header>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                II. Verwendung der Vermögenserträge und Zuwendungen
            </Header>
            <p>Die Stiftung erfüllt ihre Aufgaben aus den Erträgen des Grundstockvermögens, aus den Erträgen eines Zweckbetriebes und aus Zuwendungen, die nicht ausdrücklich zur Aufstockung des Grundstockvermögens bestimmt sind.</p>
            <p>Die Stiftung kann im Rahmen des steuerrechtlich Zulässigen freie und / oder zweckgebundene Rücklagen bilden. Freie Rücklagen können durch Beschluss des Vorstandes zur Verwirklichung des Stiftungszwecks, zum Ausgleich von Wertverlusten oder zur Erhöhung des Grundstockvermögens verwendet werden.</p>
            <p>Ein Rechtsanspruch auf Gewährung der Leistungen der Stiftung besteht für die Begünstigten aufgrund dieser Satzung nicht.</p>
            <p>Mittel dürfen nur für die satzungsmäßigen Zwecke verwendet werden. Es darf keine natürliche oder juristische Person durch Ausgaben, die dem Zweck der Stiftung fremd sind, oder durch unverhältnismäßig hohe Vergütungen begünstigt werden.</p>
            <p>Die Stiftung darf treuhänderisch Stiftungen und andere Zweckvermögen verwalten.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                III. Verwaltung des Vermögens
            </Header>
            <p>Die Verwaltung des Vermögens soll dem Gedanken eines nachhaltigen, sozialen und umweltverträglichen Wirtschaftens entsprechen.</p>
            <p>Bei Neuanlagen in Aktien oder aktienähnlichen Konstrukten sowie Renten sind keine Investitionen vorzusehen: </p>
            <List ordered>
                <List.Item>Unternehmen, die ihre Rohstoffe auf umweltschädigende Art gewinnen oder ihr Geld nachweislich mit ausbeuterischer Kinderarbeit oder anderen Verletzungen der Kinder- und Menschenrechte verdienen; </List.Item>
                <List.Item>Hersteller und Vertreiber von Rüstungsgütern, Kernenergie und Tabakwaren;</List.Item>
                <List.Item>Hersteller und Vertreiber von Pornografie und Glücksspielen;</List.Item>
                <List.Item>Obligationen von Staaten; die die „Konvention über die Rechte des Kindes“ der UNICEF vom 20. November 1989 missachten und verletzen.</List.Item>
            </List>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                IV. Risikomanagement
            </Header>
            <p>Maximal 30 Prozent des Stiftungsvermögens darf in Anlagen mit höherem Risiko angelegt werden. Mindestens 70 Prozent des Vermögens sind in sichereren Anlagen mit regelmäßigen Erträgen anzulegen.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>

            </Header>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>

            </Header>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Zur Ihrer weiteren Information:
            </Header>
            <p>Weitere Informationen zu „Konvention über die Rechte des Kindes“ finden Sie zum Download auf der <a href="https://www.unicef.de/informieren/materialien" className={'primary'}>UNICEF Website</a>.</p>
        </Container>
    </Segment>
);

export default GuideLines;